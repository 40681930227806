import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOKEN_SERVICE } from '@mhe/ol-platform/auth';
import * as readerConfigQuery from '@mhe/reader/core/state/configuration/configuration.selectors';
import { Store, select } from '@ngrx/store';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as readerSessionQuery from '@mhe/reader/state/session/session.selectors';
import * as configQuery from '@mhe/reader/state/config/config.selectors';
import { ReaderTokenService } from '@mhe/reader/tokens';
import { GoogleAnalyticsService } from '@mhe/reader/features/analytics';
import { ReaderConfigStore, ReaderStore } from '../reader/state';

// TODO Is this component deprecated????
@Component({
  selector: 'reader-ui-epub-ui-viewer',
  templateUrl: './epub-ui-viewer.component.html',
})
export class EpubUiViewerComponent {
  readonly config$ = this.store.pipe(select(readerConfigQuery.configState));

  // route data
  readonly id$ = this.route.params.pipe(map((params) => params.id));
  readonly spine$ = this.route.params.pipe(map((params) => params.spine));
  readonly hash$ = this.route.fragment.pipe(map((f) => (f ? `#${f}` : f)));

  readonly url$ = (id: string): Observable<string> =>
    this.store.pipe(select(configQuery.getEpubUrlById, { id }));

  readonly epubUrl$ = this.id$.pipe(
    switchMap((id) => this.url$(id)),
    tap((url) => {
      if (!url) {
        throw new Error('Epub not found in LTI parameters');
      }
    }),
    catchError((error) => {
      console.error(error);
      void this.router.navigate(['/not-found'], { skipLocationChange: true });
      return EMPTY;
    }),
  );

  readonly hasSession$ = this.store.pipe(select(readerSessionQuery.hasSession));

  readonly timeoutDate$ = this.store.pipe(
    select(readerSessionQuery.getExpirationDate),
  );

  readonly showSessionModal$ = this.store.pipe(select(readerSessionQuery.showSessionModal));

  readonly showTimeoutModalFor$ = this.store.pipe(
    select(readerSessionQuery.getWarningThreshold),
    map((showFor) => showFor / 1000),
  );

  readonly showAiAssist$ = this.readerStore.showAiAssist$;
  readonly aiAssistIsEnabled$ = combineLatest([
    this.readerConfigStore.isAiAssistEnabled$,
    this.readerStore.isFactoryMetadataAiAssistEnabled$,
  ]).pipe(
    map(([configEnabled, factoryEnabled]) => configEnabled && factoryEnabled),
  );

  constructor(
    private readonly ga: GoogleAnalyticsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    private readonly readerStore: ReaderStore,
    private readonly readerConfigStore: ReaderConfigStore,
    @Inject(TOKEN_SERVICE) private readonly tokenService: ReaderTokenService,
  ) {}

  refreshSession(): void {
    this.tokenService
      .refreshToken()
      .pipe(tap((response) => this.tokenService.setToken(response)))
      .subscribe();
  }

  endSession(): void {
    void this.router.navigateByUrl('401', { skipLocationChange: true });
  }

  onModalToggle(open: boolean): void {
    if (open) {
      this.ga.event({
        eventCategory: 'Dialog',
        eventAction: 'Session Timeout',
      });
    }
  }
}
