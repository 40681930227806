/* eslint-disable @typescript-eslint/naming-convention */
import * as readerConfigActions from '@mhe/reader/core/state/configuration/configuration.actions';
import * as errorsActions from '@mhe/reader/global-store/errors/errors.actions';
import * as readerSessionActions from '@mhe/reader/state/session/session.actions';
import { LtiParams } from '@mhe/reader/models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LtiService } from './lti.service';
import * as configActions from '@mhe/reader/state/config/config.actions';
import { mapLtiToRuntimeConfig } from './lti-to-runtime-config';
import { ReaderTokenService } from '@mhe/reader/tokens';
import { PendoService } from '@mhe/reader/features/analytics';
import { firstValueFrom } from 'rxjs';

const errorRoutes = ['0', '401', '403', '404', '500'];

export function loadLtiParameters(
  store: Store,
  ltiService: LtiService,
  router: Router,
  tokenService: ReaderTokenService,
  pendo: PendoService,
): () => Promise<void> {
  // Since we change the way we handle the errors from epub factory
  // We don't want to make the lti request if the page is inside the error routes
  if (errorRoutes.includes(window.location.pathname.replace('/', ''))) {
    return async() => await Promise.resolve();
  }
  return async() => {
    await ltiService.ltiParameters$
      .toPromise()
      .then(async(params: LtiParams) => {
        ltiToStore(params);

        pendo.injectSnippet(params, () => {
          pendo.initialize(params);
        });

        // EPR-8279 block rendering Aleks content if the user copies a link and opens it in a new tab
        if (params.oauth_consumer_key === 'aleks' && !document.referrer.includes('.aleks.com')) {
          await router.navigateByUrl('401', { skipLocationChange: true });
          return;
        }

        await seedSessionToken(params);
      })
      .catch((error) => {
        void router.navigateByUrl(error.status?.toString(), { skipLocationChange: true });
      });
  };

  /** sub LTI dependent functions */
  function ltiToStore(lti: LtiParams): void {
    store.dispatch(readerConfigActions.SetPartialRuntimeFeatures(mapLtiToRuntimeConfig(lti)));
    const { context_id, custom_epub_url } = lti;
    const epub = { [context_id]: custom_epub_url };
    store.dispatch(configActions.setEpubDictionary({ epub }));
  }

  async function seedSessionToken({
    custom_readerx_token: accessToken,
    custom_readerx_session_disabled: sessionDisabled,
  }: LtiParams): Promise<void> {
    // start session for reader APP
    store.dispatch(readerSessionActions.SessionStart({ session: { accessToken } }));

    if (!accessToken) {
      // let reader COMPONENT know session has already ended
      // to avoid an unnecessary http request being made
      store.dispatch(errorsActions.sessionEnded());
    } else if (sessionDisabled !== 'true') {
      // call global-session on app init to check
      // whether token/session is still good so we
      // can fail early if needed
      await firstValueFrom(tokenService.refreshToken())
        .then((token) => tokenService.setToken(token))
        .catch(() => {
          // let reader COMPONENT know session has already ended
          store.dispatch(errorsActions.sessionEnded());
        });
    }
  }
}

export * from './lti-to-runtime-config';
export * from './lti.service';
