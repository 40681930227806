<li tabindex="0" aria-level="1" [attr.aria-expanded]="isInfoVisible">
  <div style="position: relative">
    <i
      role="button"
      [attr.aria-label]="'search instructions'"
      class="infobtn dpg-icon dpg-icon-system-alert-info"
      (click)="handleInfoClick($event)"
      (keydown.escape)="closeInfo($event)"
      (keydown.enter)="handleInfoClick($event)"
      (keydown.Space)="handleInfoClick($event)"
    >
    </i>
    <div
      class="info"
      [ngClass]="{ visible: isInfoVisible, 'left': isLeftAligned }"
      [attr.aria-hidden]="!isInfoVisible"
      [innerHTML]="'contents.toc_info' | translate"
    ></div>
  </div>
</li>
